import React, { useContext, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from "@mui/material";

import { Translate } from "@core/Translate";

import { CustomerContext } from "@contexts/Customer";

import { useServiceOrder } from "@hooks/serviceOrders";

import { BreadcrumbKey } from "@type/breadcrumbs";

export const Breadcrumbs: React.FC = () => {
  const intl = useIntl();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const { customer } = useContext(CustomerContext);

  const { onGetServiceOrder, serviceOrder } = useServiceOrder();

  const getServiceOrderId = (pathnames: string[]): string | null => {
    const serviceOrderIndex = pathnames.findIndex(
      (path) => path === "service-orders"
    );
    if (serviceOrderIndex !== -1 && pathnames.length > serviceOrderIndex + 1) {
      return pathnames[serviceOrderIndex + 1];
    }
    return null;
  };

  const serviceOrderId = useMemo(
    () => getServiceOrderId(pathnames),
    [pathnames]
  );

  useEffect(() => {
    if (serviceOrderId) {
      onGetServiceOrder(serviceOrderId);
    }
  }, [serviceOrderId]);

  const getBreadcrumbName = (value: string, index: number): string => {
    const idMatch = value.match(
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    );

    if (index === 0 && value === customer?.id) {
      return customer.name;
    }

    if (Object.values(BreadcrumbKey).includes(value as BreadcrumbKey)) {
      const key = value as keyof typeof BreadcrumbKey;
      if (Object.keys(BreadcrumbKey).includes(key)) {
        return Translate.breadcrumb(intl, BreadcrumbKey[key]);
      }
    }

    if (index === 0 && value === customer?.id) {
      return customer.name;
    }

    if (idMatch && index === 2) {
      return serviceOrder?.slug || "...";
    }

    if (idMatch && index === 4) {
      return Translate.breadcrumb(intl, BreadcrumbKey["application"]);
    }

    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  const breadcrumbItems = pathnames
    .filter((path) => path !== "customers")
    .map((value, index) => {
      const to = `/${pathnames.slice(0, index + 2).join("/")}`;
      const name = getBreadcrumbName(value, index);
      return { name, to };
    });

  const isNonClickableItem = (item: { name: string; to: string }): boolean => {
    return (
      item.name === customer?.name ||
      item.name === Translate.breadcrumb(intl, BreadcrumbKey["applications"]) ||
      item.name ===
        Translate.breadcrumb(intl, BreadcrumbKey["create-application"]) ||
      item.name === Translate.breadcrumb(intl, BreadcrumbKey["registration"])
    );
  };

  return (
    <MUIBreadcrumbs maxItems={3} aria-label="breadcrumb">
      {breadcrumbItems.map((item, index) => {
        const last = index === breadcrumbItems.length - 1;
        const nonClickable = isNonClickableItem(item);

        return last || nonClickable ? (
          <Typography
            color="text.primary"
            key={item.to}
            sx={{ whiteSpace: "pre" }}
          >
            {item.name}
          </Typography>
        ) : (
          <Link
            component={RouterLink}
            color="inherit"
            to={item.to}
            key={item.to}
            sx={{ whiteSpace: "pre" }}
          >
            {item.name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};
