import { gql } from "@apollo/client";

export const GET_APPLICATION = gql`
  query GetApplication($applicationId: ID!, $customerId: ID!) {
    findCustomerApplicationById(
      applicationId: $applicationId
      customerId: $customerId
    ) {
      id
      applicationType
      analysisMethod
      averageRateFlow
      averageHeight
      averageSpeed
      totalAppliedOverdoseArea

      totalAppliedArea
      totalDistanceTraveled
      totalExternalAppliedArea
      totalInternalAppliedArea
      totalInternalAppliedOverlapArea
      totalNotAppliedArea
      totalTargetFieldArea

      averageFailureScore
      averageSuccessScore
      averageUniformityScore
      averageWasteScore

      averageSampleRate
      displacementMargin

      totalCostByArea
      totalCostByExternalAppliedArea
      totalCostByInternalAppliedArea
      totalCostByInternalAppliedOverlapArea

      totalCostByTargetFieldArea
      totalCostByAppliedArea
      totalAppliedOverlapArea

      plannedAverageRateFlow
      flowRateDataByGroup {
        finalGroupRate
        flowRateGroup
        groupArea
        groupColor
        groupPercentual
        initialGroupRate
      }

      plannedAverageHeight
      heightDataByGroup {
        heightGroup
        groupArea
        groupColor
        groupPercentual
        initialGroupHeight
        finalGroupHeight
      }

      layers {
        id
        bbox
        type
        metadata {
          url
        }
      }
      fields {
        id
        averageFailureScore
        averageSuccessScore
        averageUniformityScore
        totalInternalAppliedArea
        totalInternalAppliedOverlapArea
        totalNotAppliedArea
        totalTargetFieldArea
        deletedAt
        field {
          id
          name
          farmHierarchy
          farm {
            id
            name
          }
        }
      }
      farms {
        id
        farm {
          id
          name
        }
        totalAppliedOverdoseArea
        totalFarmArea
        totalInternalAppliedArea
        totalInternalAppliedOverlapArea
        totalNotAppliedArea
        updatedAt
        averageUniformityScore
        averageSuccessScore
        averageFailureScore
      }
      schedules {
        id
        startedIn
        finishedIn
        comments
        swathWidth
        runwayDistance
        pilot {
          id
          licenseId
          name
        }
        vehicle {
          id
          name
          model {
            id
            name
          }
          aerialCompany {
            id
            name
          }
        }
      }
      serviceOrder {
        id
        slug
      }
      logs {
        id
        createdAt
        user {
          id
          name
        }
      }
      products {
        id
        dosage
        amount {
          currency
          value
        }
        deletedAt
        customerProduct {
          id
          alias
          measurementUnit
          amount {
            currency
            value
          }
        }
      }
      season {
        id
        crop
        startedIn
        finishedIn
      }
    }
  }
`;

export const LIST_APPLICATION_ATTACHMENTS = gql`
  query ListApplicationAttachment($applicationId: ID!, $customerId: ID!) {
    findCustomerApplicationAttachment(
      applicationId: $applicationId
      customerId: $customerId
    ) {
      expiresIn
      filename
      url
    }
  }
`;

export const GET_APPLICATION_LOG = gql`
  query GetApplicationLog($applicationLogId: ID!, $customerId: ID!) {
    findApplicationLogById(
      applicationLogId: $applicationLogId
      customerId: $customerId
    ) {
      id
      filename
      format
      status
      metadata {
        rawApplicationLog {
          expiresIn
          url
        }
      }
    }
  }
`;

export const LIST_APPLICATION_LOGS = gql`
  query ListApplicationLogs(
    $findApplicationLogInput: FindApplicationLogInput!
    $paginateApplicationLogInput: PaginateInput
    $sortApplicationLogInput: SortApplicationLogInput
  ) {
    findApplicationLog(
      findApplicationLogInput: $findApplicationLogInput
      paginateApplicationLogInput: $paginateApplicationLogInput
      sortApplicationLogInput: $sortApplicationLogInput
    ) {
      applicationLogs {
        id
        filename
        format
        status
        createdAt
      }
      paginate {
        limit
        offset
        total
      }
    }
  }
`;

export const GET_APPLICATION_LAYER = gql`
  query GetApplicationLayer($applicationId: ID!, $customerId: ID!) {
    findCustomerApplicationLayerById(
      applicationId: $applicationId
      customerId: $customerId
    ) {
      layers {
        id
        bbox
        type
        metadata {
          url
        }
      }
    }
  }
`;

export const GET_APPLICATION_REPORT = gql`
  query GetApplicationReport($applicationId: ID!, $customerId: ID!) {
    findCustomerApplicationReport(
      applicationId: $applicationId
      customerId: $customerId
    ) {
      id
      type
      url
      expiresIn
    }
  }
`;

export const GET_APPLICATION_LAYERS_URL = gql`
  query GetApplicationLayersUrl($input: ApplicationLayersInputFilter!) {
    applicationLayers(input: $input) {
      url
    }
  }
`;
