import { defineMessages } from "react-intl";

export const ServiceOrderStatusMessages = defineMessages({
  Complete: {
    defaultMessage: "Processado",
    id: "0Vbfi8",
    description: "processed label",
  },
  InProgress: {
    defaultMessage: "Processando Log",
    id: "yMBp28",
    description: "processing log label",
  },
  Processing: {
    defaultMessage: "Processando Aplicação",
    id: "19Rv0D",
    description: "processing application label",
  },
  ReadyToProcess: {
    defaultMessage: "Preencher informações",
    id: "Rd3aP4",
    description: "fill info label",
  },
  WaitingJob: {
    defaultMessage: "Aguardando envio de logs",
    id: "/gAAVw",
    description: "waiting for log",
  },
});
