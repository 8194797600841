import { useState } from "react";
import { useIntl } from "react-intl";

import { useLazyQuery } from "@apollo/client";

import { LIST_CUSTOMERS } from "@graphql/queries/CustomerQuery";
import {
  ListCustomersInputFilter,
  PagingInput,
} from "@graphql/types/graphql-global-types";
import {
  ListCustomers as ListCustomersInput,
  ListCustomersVariables,
} from "@graphql/types/ListCustomers";

import { Toast } from "@core/Toast";

import { CUSTOMERS_PAGE_SIZE } from "@utils/constants";
import { graphQLError } from "@utils/errorMessages";
import { notEmpty } from "@utils/notEmpty";

import { ListCustomers } from "@type/customers";
import { Paging } from "@type/paging";

export const useCustomers = () => {
  const intl = useIntl();

  const [customers, setCustomers] = useState<ListCustomers[]>([]);
  const [customersPaging, setCustomersPaging] = useState<Paging | null>(null);

  const [loadCustomers, { loading }] = useLazyQuery<
    ListCustomersInput,
    ListCustomersVariables
  >(LIST_CUSTOMERS, {
    onCompleted: ({ customers }) => {
      const filteredCustomers = customers.customers.filter(notEmpty);
      setCustomers(filteredCustomers);
      setCustomersPaging(customers.paging);
    },
    onError: (error) => {
      const messages = graphQLError(error);

      if (messages.length) {
        messages.forEach((message) => Toast.error(message));
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Ocorreu um erro ao carregar os usuários. Tente novamente mais tarde!",
            id: "lO2+jv",
            description: "error loading customers",
          })
        );
      }
    },
    fetchPolicy: "network-only",
  });

  const onLoadCustomers = ({
    filter,
    paging = {
      limit: CUSTOMERS_PAGE_SIZE,
      offset: 0,
    },
  }: {
    filter?: ListCustomersInputFilter;
    paging: PagingInput;
  }) => {
    return loadCustomers({
      variables: {
        customersInput: {
          filter,
          paging,
        },
      },
    });
  };

  return {
    customers,
    onLoadCustomers,
    loading,
    customersPaging,
  };
};
