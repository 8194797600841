import { gql } from "@apollo/client";

export const LIST_AGRICULTURAL_DEFENSIVE_PRODUCTS = gql`
  query ListAgriculturalDefensiveProducts(
    $findServiceOrderInput: ListAgriculturalDefensiveProductsInputFilter!
    $paginateAgriculturalDefensiveProductInput: PaginateInput
  ) {
    agriculturalDefensiveProducts(
      findServiceOrderInput: $findServiceOrderInput
      paginateAgriculturalDefensiveProductInput: $paginateAgriculturalDefensiveProductInput
    ) {
      agriculturalDefensiveProducts {
        id
        manufacturerName
        name
        serviceType
        classType
        measurementUnit
      }
      paginate {
        limit
        offset
        total
      }
    }
  }
`;
