/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApplicationAnalysisMethod {
  Border = "Border",
  Complete = "Complete",
  Partial = "Partial",
}

export enum ApplicationDateType {
  AnalysisDate = "AnalysisDate",
  ApplicationDate = "ApplicationDate",
}

export enum ApplicationDeletedReason {
  DuplicatedApplication = "DuplicatedApplication",
  WrongApplicationData = "WrongApplicationData",
  WrongServiceOrderApplication = "WrongServiceOrderApplication",
}

export enum ApplicationLayerName {
  Applied = "Applied",
  AppliedFlowRateBySegment = "AppliedFlowRateBySegment",
  AppliedFlowRateBySwath = "AppliedFlowRateBySwath",
  AppliedHeightBySegment = "AppliedHeightBySegment",
  AppliedOverlap = "AppliedOverlap",
  AppliedPoint = "AppliedPoint",
  AppliedSegment = "AppliedSegment",
  AppliedWithoutOverlap = "AppliedWithoutOverlap",
  CroppedNotApplied = "CroppedNotApplied",
  ExternalApplied = "ExternalApplied",
  ExternalAppliedOverlap = "ExternalAppliedOverlap",
  InternalApplied = "InternalApplied",
  InternalAppliedOverlap = "InternalAppliedOverlap",
  NotApplied = "NotApplied",
  PathTraveled = "PathTraveled",
  RestrictionField = "RestrictionField",
  TargetField = "TargetField",
}

export enum ApplicationLogFormat {
  Agnav = "Agnav",
  Agpilotx = "Agpilotx",
  Dynanav = "Dynanav",
  Galindo = "Galindo",
  Satloc = "Satloc",
  Travicar = "Travicar",
  Trimble = "Trimble",
  Unknown = "Unknown",
  Verion = "Verion",
}

export enum ApplicationLogStatus {
  AwaitingProcessing = "AwaitingProcessing",
  Invalidated = "Invalidated",
  Processed = "Processed",
  Processing = "Processing",
  Uploaded = "Uploaded",
  WaitingUpload = "WaitingUpload",
}

export enum ApplicationReportType {
  Performance = "Performance",
}

export enum ApplicationScheduleStatus {
  Executed = "Executed",
  Planned = "Planned",
}

export enum ApplicationStatus {
  Active = "Active",
  Complete = "Complete",
  InAnalysis = "InAnalysis",
}

export enum ApplicationType {
  Aerial = "Aerial",
  Terrestrial = "Terrestrial",
}

export enum Crop {
  Avocado = "Avocado",
  Banana = "Banana",
  Bean = "Bean",
  Brachiaria = "Brachiaria",
  Cereals = "Cereals",
  Citrus = "Citrus",
  Cocoa = "Cocoa",
  Coconut = "Coconut",
  Coffee = "Coffee",
  Corn = "Corn",
  Cotton = "Cotton",
  Cover = "Cover",
  Eucalyptus = "Eucalyptus",
  Grape = "Grape",
  Millet = "Millet",
  Oat = "Oat",
  Orange = "Orange",
  Peanut = "Peanut",
  PearlMillet = "PearlMillet",
  Pineapple = "Pineapple",
  Potato = "Potato",
  Rice = "Rice",
  Sorghum = "Sorghum",
  Soybean = "Soybean",
  Stylosanthes = "Stylosanthes",
  Sugarcane = "Sugarcane",
  Sunflower = "Sunflower",
  SunnHemp = "SunnHemp",
  Unknown = "Unknown",
  Wheat = "Wheat",
}

export enum CurrencyV2 {
  BRL = "BRL",
  EUR = "EUR",
  USD = "USD",
}

export enum CustomerRole {
  CustomerAdmin = "CustomerAdmin",
  CustomerPilot = "CustomerPilot",
  PartnerAdmin = "PartnerAdmin",
}

export enum FileStatus {
  Invalid = "Invalid",
  Pending = "Pending",
  Processed = "Processed",
  Processing = "Processing",
  Validated = "Validated",
}

export enum MeasurementUnitV2 {
  Acre = "Acre",
  Celsius = "Celsius",
  Degrees = "Degrees",
  Fahrenheit = "Fahrenheit",
  Hectare = "Hectare",
  Kilogram = "Kilogram",
  Kilometre = "Kilometre",
  Litre = "Litre",
  Metre = "Metre",
  Millimeter = "Millimeter",
  Percentage = "Percentage",
}

export enum Permission {
  ReadCustomer = "ReadCustomer",
  ReadCustomerAerialCompany = "ReadCustomerAerialCompany",
  ReadCustomerAircraft = "ReadCustomerAircraft",
  ReadCustomerApplication = "ReadCustomerApplication",
  ReadCustomerApplicationLog = "ReadCustomerApplicationLog",
  ReadCustomerApplicationWeather = "ReadCustomerApplicationWeather",
  ReadCustomerFarm = "ReadCustomerFarm",
  ReadCustomerField = "ReadCustomerField",
  ReadCustomerPilot = "ReadCustomerPilot",
  ReadCustomerProduct = "ReadCustomerProduct",
  ReadCustomerReport = "ReadCustomerReport",
  ReadCustomerRestrictionField = "ReadCustomerRestrictionField",
  ReadCustomerScheduleFlight = "ReadCustomerScheduleFlight",
  ReadCustomerSeason = "ReadCustomerSeason",
  ReadCustomerServiceOrder = "ReadCustomerServiceOrder",
  ReadCustomerTractor = "ReadCustomerTractor",
  ReadCustomerUser = "ReadCustomerUser",
  ReadProduct = "ReadProduct",
  ReadUser = "ReadUser",
  WriteCustomer = "WriteCustomer",
  WriteCustomerAerialCompany = "WriteCustomerAerialCompany",
  WriteCustomerAircraft = "WriteCustomerAircraft",
  WriteCustomerApplication = "WriteCustomerApplication",
  WriteCustomerApplicationLog = "WriteCustomerApplicationLog",
  WriteCustomerApplicationWeather = "WriteCustomerApplicationWeather",
  WriteCustomerFarm = "WriteCustomerFarm",
  WriteCustomerField = "WriteCustomerField",
  WriteCustomerPilot = "WriteCustomerPilot",
  WriteCustomerProduct = "WriteCustomerProduct",
  WriteCustomerReport = "WriteCustomerReport",
  WriteCustomerRestrictionField = "WriteCustomerRestrictionField",
  WriteCustomerScheduleFlight = "WriteCustomerScheduleFlight",
  WriteCustomerSeason = "WriteCustomerSeason",
  WriteCustomerServiceOrder = "WriteCustomerServiceOrder",
  WriteCustomerServiceOrderStatus = "WriteCustomerServiceOrderStatus",
  WriteCustomerTractor = "WriteCustomerTractor",
  WriteCustomerUser = "WriteCustomerUser",
  WritePartnerAdmin = "WritePartnerAdmin",
  WriteProduct = "WriteProduct",
  WriteUser = "WriteUser",
  parse = "parse",
}

export enum ProductClassTypeV2 {
  Acaricide = "Acaricide",
  AcaricideAntKillerInsecticide = "AcaricideAntKillerInsecticide",
  AcaricideBactericideFungicide = "AcaricideBactericideFungicide",
  AcaricideFungicide = "AcaricideFungicide",
  AcaricideFungicideInsecticide = "AcaricideFungicideInsecticide",
  AcaricideInsecticide = "AcaricideInsecticide",
  AcaricideInsecticideNematicide = "AcaricideInsecticideNematicide",
  AcaricideMicrobiological = "AcaricideMicrobiological",
  AcaricideMicrobiologicalInsecticideMicrobiological = "AcaricideMicrobiologicalInsecticideMicrobiological",
  Adjuvant = "Adjuvant",
  Adulticide = "Adulticide",
  AntKiller = "AntKiller",
  AntKillerFungicideHerbicideInsecticideNematicide = "AntKillerFungicideHerbicideInsecticideNematicide",
  AntKillerFungicideHerbicideNematicide = "AntKillerFungicideHerbicideNematicide",
  AntKillerInsecticide = "AntKillerInsecticide",
  Bactericide = "Bactericide",
  BactericideFungicide = "BactericideFungicide",
  BactericideMicrobiologicalFungicideMicrobiological = "BactericideMicrobiologicalFungicideMicrobiological",
  Biological = "Biological",
  BiologicalControlAgent = "BiologicalControlAgent",
  Fertilizer = "Fertilizer",
  FoliarNutrition = "FoliarNutrition",
  FumigantInsecticide = "FumigantInsecticide",
  Fungicide = "Fungicide",
  FungicideHerbicideNematicide = "FungicideHerbicideNematicide",
  FungicideInsecticide = "FungicideInsecticide",
  FungicideInsecticideNematicide = "FungicideInsecticideNematicide",
  FungicideMicrobiological = "FungicideMicrobiological",
  FungicideMicrobiologicalNematicideMicrobiological = "FungicideMicrobiologicalNematicideMicrobiological",
  FungicideNematicide = "FungicideNematicide",
  FungicideNematicideMicrobiological = "FungicideNematicideMicrobiological",
  Herbicide = "Herbicide",
  HerbicidePlantGrowthRegulator = "HerbicidePlantGrowthRegulator",
  Insecticide = "Insecticide",
  InsecticideMicrobiological = "InsecticideMicrobiological",
  InsecticideNematicide = "InsecticideNematicide",
  InsecticidePlantGrowthRegulator = "InsecticidePlantGrowthRegulator",
  Larvicide = "Larvicide",
  Microbiological = "Microbiological",
  Molluscicide = "Molluscicide",
  Nematicide = "Nematicide",
  NematicideMicrobiological = "NematicideMicrobiological",
  Pheromone = "Pheromone",
  PheromoneInsecticide = "PheromoneInsecticide",
  Phytohormone = "Phytohormone",
  PlantActivator = "PlantActivator",
  PlantGrowthRegulator = "PlantGrowthRegulator",
  Ripener = "Ripener",
  SeedProtectant = "SeedProtectant",
  Seeding = "Seeding",
  Termiticide = "Termiticide",
  TermiticideAntKillerInsecticide = "TermiticideAntKillerInsecticide",
  TermiticideInsecticide = "TermiticideInsecticide",
  Unknown = "Unknown",
}

export enum ProductServiceTypeV2 {
  AdjuvantApplication = "AdjuvantApplication",
  FertilizerApplication = "FertilizerApplication",
  PesticideApplication = "PesticideApplication",
  Seeding = "Seeding",
  Unknown = "Unknown",
}

export enum ReportStatus {
  Complete = "Complete",
  Processing = "Processing",
}

export enum RestrictionFieldType {
  AnimalBreeding = "AnimalBreeding",
  City = "City",
  IsolatedHouses = "IsolatedHouses",
  Neighborhood = "Neighborhood",
  NeighboringProperty = "NeighboringProperty",
  PermanentPreservationArea = "PermanentPreservationArea",
  Unknown = "Unknown",
  Village = "Village",
  WaterSource = "WaterSource",
  WaterSupply = "WaterSupply",
}

export enum Role {
  CustomerAdmin = "CustomerAdmin",
  CustomerPilot = "CustomerPilot",
  PartnerAdmin = "PartnerAdmin",
  SystemAdmin = "SystemAdmin",
  SystemSupport = "SystemSupport",
  parse = "parse",
}

export enum ServiceOrderStatus {
  Complete = "Complete",
  InProgress = "InProgress",
  Processing = "Processing",
  ReadyToProcess = "ReadyToProcess",
  WaitingJob = "WaitingJob",
}

export enum SortType {
  asc = "asc",
  desc = "desc",
}

export enum TimeZone {
  AmericaAraguaina = "AmericaAraguaina",
  AmericaBahia = "AmericaBahia",
  AmericaBelem = "AmericaBelem",
  AmericaBoaVista = "AmericaBoaVista",
  AmericaCampoGrande = "AmericaCampoGrande",
  AmericaCuiaba = "AmericaCuiaba",
  AmericaEirunepe = "AmericaEirunepe",
  AmericaFortaleza = "AmericaFortaleza",
  AmericaMaceio = "AmericaMaceio",
  AmericaManaus = "AmericaManaus",
  AmericaNewYork = "AmericaNewYork",
  AmericaNoronha = "AmericaNoronha",
  AmericaPortoAcre = "AmericaPortoAcre",
  AmericaPortoVelho = "AmericaPortoVelho",
  AmericaRecife = "AmericaRecife",
  AmericaRioBranco = "AmericaRioBranco",
  AmericaSantarem = "AmericaSantarem",
  AmericaSaoPaulo = "AmericaSaoPaulo",
  AmericaTegucigalpa = "AmericaTegucigalpa",
}

export enum WeatherDateType {
  FinishedDate = "FinishedDate",
  StartedDate = "StartedDate",
}

export enum WeatherProvider {
  WeatherApi = "WeatherApi",
}

export interface AmountInput {
  currency?: CurrencyV2 | null;
  value?: number | null;
}

export interface ApplicationLayersInputFilter {
  applicationId: string;
  customerId: string;
}

export interface CreateAerialCompanyAircraftInput {
  enrollmentId: string;
  modelId: string;
}

export interface CreateAerialCompanyInput {
  aircrafts: (CreateAerialCompanyAircraftInput | null)[];
  customerId: string;
  documentNumber?: string | null;
  name: string;
  pilots: (CreateAerialCompanyPilotInput | null)[];
  tractors: (CreateAerialCompanyTractorInput | null)[];
}

export interface CreateAerialCompanyPilotInput {
  licenseId: string;
  name: string;
}

export interface CreateAerialCompanyTractorInput {
  modelId: string;
  name: string;
}

export interface CreateApplicationFieldInput {
  boundaries: GeometryInput;
  fieldId: string;
  restrictionOverlappingArea?: number | null;
  totalAppliedArea?: number | null;
}

export interface CreateApplicationInput {
  analysisByTargetField?: boolean | null;
  analysisMethod: ApplicationAnalysisMethod;
  applicationType?: ApplicationType | null;
  customerId: string;
  flowRangeVariation?: number | null;
  heightRangeVariation?: number | null;
  plannedAverageHeight?: number | null;
  plannedAverageRateFlow?: number | null;
  serviceOrders?: ServiceOrderInput[] | null;
}

export interface CreateApplicationProductInput {
  customerProductId: string;
  dosage: number;
}

export interface CreateApplicationRestrictionFieldInput {
  bufferRadius: number;
  restrictionId: string;
}

export interface CreateApplicationScheduleInput {
  comments?: string | null;
  finishedIn?: any | null;
  pilotId: string;
  runwayDistance?: number | null;
  startedIn?: any | null;
  vehicleId: string;
}

export interface CreateCustomerInput {
  externalId?: string | null;
  location?: string | null;
  name: string;
}

export interface CreateCustomerProductInput {
  alias: string;
  amount?: AmountInput | null;
  classType: ProductClassTypeV2;
  customerId: string;
  manufacturerName?: string | null;
  measurementUnit: MeasurementUnitV2;
  productId?: string | null;
}

export interface CreateCustomerUserInput {
  customerId: string;
  email: string;
  name: string;
  pilotId?: string | null;
  role: CustomerRole;
  timezone: TimeZone;
}

export interface CreateFarmInput {
  customerId: string;
  name: string;
  namespace: string;
  parentId?: string | null;
}

export interface CreateFieldInput {
  boundaries: GeometryInput;
  customerId: string;
  externalId: string;
  farmId: string;
}

export interface CreatePostFlightInput {
  aerialCompanyId?: string | null;
  applicationType?: ApplicationType | null;
  customerId: string;
  fields: CreateApplicationFieldInput[];
  observation?: string | null;
  plannedAverageHeight?: number | null;
  plannedAverageRateFlow?: number | null;
  products?: (CreateApplicationProductInput | null)[] | null;
  schedules?: (CreateApplicationScheduleInput | null)[] | null;
  seasonId: string;
  slug?: string | null;
  status: ServiceOrderStatus;
}

export interface CreatePreFlightInput {
  aerialCompanyId?: string | null;
  applicationType?: ApplicationType | null;
  customerId: string;
  fieldImage?: string | null;
  fields: CreateApplicationFieldInput[];
  isGeneratingPilotLapMap?: boolean | null;
  observation?: string | null;
  plannedAverageHeight?: number | null;
  plannedAverageRateFlow?: number | null;
  products?: (CreateApplicationProductInput | null)[] | null;
  restrictionFields?: CreateApplicationRestrictionFieldInput[] | null;
  schedules?: (CreateApplicationScheduleInput | null)[] | null;
  seasonId: string;
  slug?: string | null;
  status: ServiceOrderStatus;
}

export interface CreateRestrictionFieldInput {
  customerId: string;
  geometry: GeometryInput;
  name: string;
  type: RestrictionFieldType;
  warningRadius: number;
}

export interface CreateSeasonInput {
  crop: Crop;
  customerId: string;
  finishedIn: any;
  startedIn: any;
}

export interface CreateServiceOrderInput {
  customerId: string;
  externalId?: string | null;
  fields?: (ServiceOrderFieldInput | null)[] | null;
  harvestId?: string | null;
  products?: (ServiceOrderFieldProductInput | null)[] | null;
  schedules?: (ServiceOrderScheduleInput | null)[] | null;
  serviceProviderId: string;
  status?: ServiceOrderStatus | null;
}

export interface CreateUploadUrlInput {
  customerId: string;
  filename: string;
  serviceOrderId?: string | null;
}

export interface DeleteCustomerApplicationInput {
  applicationId: string;
  customerId: string;
  deletedReason: ApplicationDeletedReason;
}

export interface FetchAllReportsInput {
  filter: InputFilter;
}

export interface FetchReportInput {
  customerId?: string | null;
}

export interface FindAerialCompanyInput {
  customerId: string;
  name?: string | null;
  withDeleted?: boolean | null;
}

export interface FindApplicationLogInput {
  applicationId?: string | null;
  customerId: string;
  filename?: string | null;
  format?: ApplicationLogFormat | null;
  status?: ApplicationLogStatus | null;
}

export interface FindCustomerProductInput {
  alias?: string | null;
  classType?: ProductClassTypeV2 | null;
  customerId: string;
  manufacturerName?: string | null;
}

export interface FindCustomerServiceOrderInput {
  applicationType?: ApplicationType | null;
  crops?: Crop[] | null;
  customerId: string;
  farmIds?: string[] | null;
  slug?: string | null;
  startedIn?: any | null;
  status?: ServiceOrderStatus[] | null;
}

export interface FindCustomerUserInput {
  customerId: string;
  name?: string | null;
  role?: string | null;
  userId?: string | null;
}

export interface FindFarmInput {
  filter: FindFarmInputFilter;
}

export interface FindFarmInputFilter {
  customerId: string;
  id: string;
}

export interface FindFileInput {
  filter: FindFileInputFilter;
}

export interface FindFileInputFilter {
  customerId: string;
  id: string;
}

export interface FindSeasonInput {
  crop?: Crop | null;
  customerId: string;
}

export interface FindSummaryReportInput {
  filter: FindSummaryReportInputFilter;
  orderBy?: FindSummaryReportInputSort | null;
  paging?: PagingInput | null;
}

export interface FindSummaryReportInputFilter {
  customerId: string;
  status?: ReportStatus | null;
}

export interface FindSummaryReportInputSort {
  createdAt?: SortType | null;
}

export interface FinishCustomerUploadLogsInput {
  customerId: string;
  serviceOrderId: string;
}

export interface GenerateOperationalReportInput {
  applicationId: string;
  customerId: string;
  image: string;
  sprayData?: SprayData | null;
}

export interface GenerateSummaryReportInput {
  aerialCompanyId?: string | null;
  applicationDateType?: ApplicationDateType | null;
  applicationType?: string | null;
  customerId: string;
  farmId?: string | null;
  finishedIn: any;
  pilotId?: string | null;
  seasonId?: string | null;
  startedIn: any;
  userTimezoneOffset?: number | null;
}

export interface GeoJSONFeatureExportFeatureInput {
  geometry: GeoJSONFeatureExportGeometryInput;
  properties: any;
  type: string;
}

export interface GeoJSONFeatureExportGeometryInput {
  coordinates: any[];
  type: string;
}

export interface GeoJSONFeatureExportInput {
  exportTypes: GeoJSONFeatureExportTypesInput;
  features: GeoJSONFeatureExportFeatureInput[];
  filename: string;
}

export interface GeoJSONFeatureExportTypesInput {
  job?: boolean | null;
  kml?: boolean | null;
  kmz?: boolean | null;
  no1?: boolean | null;
  prj?: boolean | null;
  shp?: boolean | null;
}

export interface GeometryInput {
  coordinates: any[];
  type: string;
}

export interface InputFilter {
  customerId?: string | null;
}

export interface JoinCustomerFieldsIgnoringTracksInput {
  bufferedBoundaries: string[];
  radiusToBuffer: number;
}

export interface ListAgriculturalDefensiveProductsInputFilter {
  classType?: ProductClassTypeV2 | null;
  name?: string | null;
}

export interface ListCustomersInput {
  filter?: ListCustomersInputFilter | null;
  paging?: PagingInput | null;
}

export interface ListCustomersInputFilter {
  name?: string | null;
}

export interface ListFarmsInput {
  filter: ListFarmsInputFilter;
  orderBy?: ListFarmsInputSort | null;
  paging?: PagingInput | null;
}

export interface ListFarmsInputFilter {
  customerId: string;
  hasChildren?: boolean | null;
  name?: string | null;
  parentId?: string | null;
}

export interface ListFarmsInputSort {
  createdAt?: SortType | null;
  name?: SortType | null;
  namespace?: SortType | null;
}

export interface ListFieldsByCustomerAndFileInput {
  filter?: ListFieldsByCustomerAndFileInputFilter | null;
  orderBy?: ListFieldsByCustomerAndFileInputSort | null;
  paging?: PagingInput | null;
}

export interface ListFieldsByCustomerAndFileInputFilter {
  applicationIntersectToleranceField?: number | null;
  customerId: string;
  fileId: string;
  name?: string | null;
}

export interface ListFieldsByCustomerAndFileInputSort {
  customerId?: SortType | null;
  fileId?: SortType | null;
}

export interface ListFieldsInput {
  filter: ListFieldsInputFilter;
  orderBy?: ListFieldsInputSort | null;
  paging?: PagingInput | null;
}

export interface ListFieldsInputFilter {
  customerId: string;
  externalId?: string | null;
  farmId?: string | null;
  ids?: string[] | null;
}

export interface ListFieldsInputSort {
  createdAt?: SortType | null;
  externalId?: SortType | null;
  farmId?: SortType | null;
}

export interface ListFilesInput {
  filter?: ListFilesInputFilter | null;
  orderBy?: ListFilesInputSort | null;
  paging?: PagingInput | null;
}

export interface ListFilesInputFilter {
  customerId: string;
  name?: string | null;
  status?: FileStatus | null;
  userId?: string | null;
}

export interface ListFilesInputSort {
  createdAt?: SortType | null;
  name?: SortType | null;
}

export interface ListOperatorsInput {
  filter?: ListOperatorsInputFilter | null;
  orderBy?: ListOperatorsInputSort | null;
  paging?: PagingInput | null;
}

export interface ListOperatorsInputFilter {
  customerId: string;
  externalId?: string | null;
  name?: string | null;
  serviceProviderId?: string | null;
}

export interface ListOperatorsInputSort {
  createdAt?: SortType | null;
  name?: SortType | null;
}

export interface ListRestrictedLocationsByFieldsInput {
  filter: ListRestrictedLocationsByFieldsInputFilter;
}

export interface ListRestrictedLocationsByFieldsInputFilter {
  customerId: string;
  distance: number;
  fieldIds: string[];
}

export interface ListRestrictedLocationsInput {
  filter: ListRestrictedLocationsInputFilter;
  orderBy?: ListRestrictedLocationsInputSort | null;
  paging?: PagingInput | null;
}

export interface ListRestrictedLocationsInputFilter {
  customerId: string;
  externalId?: string | null;
  type?: string | null;
}

export interface ListRestrictedLocationsInputSort {
  createdAt?: SortType | null;
  externalId?: SortType | null;
  type?: SortType | null;
  warningRadius?: SortType | null;
}

export interface ListVehicleManufacturersInput {
  filter: ListVehicleManufacturersInputFilter;
}

export interface ListVehicleManufacturersInputFilter {
  customerId: string;
}

export interface ListVehiclesInput {
  filter?: ListVehiclesInputFilter | null;
  orderBy?: ListVehiclesInputSort | null;
  paging?: PagingInput | null;
}

export interface ListVehiclesInputFilter {
  customerId: string;
  externalId?: string | null;
  serviceProviderId?: string | null;
}

export interface ListVehiclesInputSort {
  createdAt?: SortType | null;
  externalId?: SortType | null;
}

export interface OperationalReportInput {
  applicationId: string;
  customerId: string;
}

export interface PaginateInput {
  limit?: number | null;
  offset?: number | null;
}

export interface PagingInput {
  limit?: number | null;
  offset?: number | null;
}

export interface PilotLapMapReportInput {
  customerId: string;
  serviceOrderId: string;
}

export interface ProcessQueueInput {
  customerId: string;
  farmNameField?: string | null;
  lookupTable: any;
  objectKey: string;
  useNameElement?: boolean | null;
}

export interface ServiceOrderFieldInput {
  fieldId: string;
  id?: string | null;
  restrictedLocations?:
    | (ServiceOrderFieldRestrictedLocationInput | null)[]
    | null;
}

export interface ServiceOrderFieldProductInput {
  cost: number;
  currency: string;
  id?: string | null;
  productId: string;
  usage: number;
}

export interface ServiceOrderFieldRestrictedLocationInput {
  id?: string | null;
  restrictedLocationId: string;
  warningRadius: number;
}

export interface ServiceOrderInput {
  externalId?: string | null;
  fields?: (ServiceOrderFieldInput | null)[] | null;
  harvestId?: string | null;
  id?: string | null;
  products?: (ServiceOrderFieldProductInput | null)[] | null;
  schedules?: (ServiceOrderScheduleInput | null)[] | null;
  serviceProviderId?: string | null;
}

export interface ServiceOrderScheduleInput {
  detail?: string | null;
  endDateTime?: any | null;
  fileIds?: string[] | null;
  id?: string | null;
  operatorId: string;
  runwayDistance?: number | null;
  startDateTime?: any | null;
  vehicleId: string;
}

export interface SortAerialCompanyInput {
  createdAt?: SortType | null;
  deletedAt?: SortType | null;
  name?: SortType | null;
  updatedAt?: SortType | null;
}

export interface SortApplicationLogInput {
  createdAt?: SortType | null;
  deletedAt?: SortType | null;
  updatedAt?: SortType | null;
}

export interface SortCustomerProductInput {
  alias?: SortType | null;
  createdAt?: SortType | null;
  deletedAt?: SortType | null;
  type?: SortType | null;
  updatedAt?: SortType | null;
}

export interface SortCustomerServiceOrderInput {
  createdAt?: SortType | null;
  deletedAt?: SortType | null;
  startedIn?: SortType | null;
  updatedAt?: SortType | null;
}

export interface SortCustomerUserInput {
  createdAt?: SortType | null;
  deletedAt?: SortType | null;
  updatedAt?: SortType | null;
}

export interface SortSeasonInput {
  createdAt?: SortType | null;
  crop?: SortType | null;
  deletedAt?: SortType | null;
  finishedIn?: SortType | null;
  startedIn?: SortType | null;
  updatedAt?: SortType | null;
}

export interface SprayData {
  angle?: number | null;
  model?: string | null;
  pulverizerType?: string | null;
  type?: string | null;
}

export interface SummaryReportInput {
  customerId?: string | null;
  id: string;
  status?: ReportStatus | null;
}

export interface UpdateAerialCompanyAircraftInput {
  aircraftId: string;
  enrollmentId?: string | null;
  modelId?: string | null;
}

export interface UpdateAerialCompanyInput {
  aerialCompanyId: string;
  createAircrafts?: (CreateAerialCompanyAircraftInput | null)[] | null;
  createPilots?: (CreateAerialCompanyPilotInput | null)[] | null;
  createTractors?: (CreateAerialCompanyTractorInput | null)[] | null;
  customerId: string;
  documentNumber?: string | null;
  name?: string | null;
  updateAircrafts?: (UpdateAerialCompanyAircraftInput | null)[] | null;
  updatePilots?: (UpdateAerialCompanyPilotInput | null)[] | null;
  updateTractors?: (UpdateAerialCompanyTractorInput | null)[] | null;
}

export interface UpdateAerialCompanyPilotInput {
  licenseId?: string | null;
  name?: string | null;
  pilotId: string;
}

export interface UpdateAerialCompanyTractorInput {
  modelId?: string | null;
  name?: string | null;
  tractorId: string;
}

export interface UpdateApplicationLogInput {
  applicationId?: string | null;
  customerId: string;
  filename?: string | null;
  format?: ApplicationLogFormat | null;
  id: string;
  metadata?: UpdateApplicationLogMetadataInput | null;
  status?: ApplicationLogStatus | null;
}

export interface UpdateApplicationLogMetadataInput {
  endDateTime?: any | null;
  startDateTime?: any | null;
}

export interface UpdateCustomerProductInput {
  alias?: string | null;
  amount?: AmountInput | null;
  classType?: ProductClassTypeV2 | null;
  customerId: string;
  customerProductId: string;
  manufacturerName?: string | null;
  measurementUnit?: MeasurementUnitV2 | null;
  productId?: string | null;
}

export interface UpdateCustomerSeasonInput {
  crop?: Crop | null;
  customerId: string;
  finishedIn?: any | null;
  seasonId: string;
  startedIn?: any | null;
}

export interface UpdateFarmInput {
  customerId?: string | null;
  id: string;
  name?: string | null;
  namespace?: string | null;
  parentId?: string | null;
}

export interface UpdateFieldInput {
  boundaries?: GeometryInput | null;
  customerId: string;
  externalId?: string | null;
  farmId: string;
  id: string;
}

export interface UpdateRestrictionFieldInput {
  customerId: string;
  id: string;
  name?: string | null;
  type?: RestrictionFieldType | null;
  warningDistance?: number | null;
}

export interface UpdateServiceOrderInput {
  customerId: string;
  externalId?: string | null;
  id: string;
  schedules?: (UpdateServiceOrderScheduleInput | null)[] | null;
}

export interface UpdateServiceOrderScheduleInput {
  detail?: string | null;
  endDateTime?: any | null;
  id: string;
  operatorId?: string | null;
  runwayDistance?: number | null;
  startDateTime?: any | null;
  vehicleId?: string | null;
}

export interface UploadCustomerApplicationAttachmentInput {
  applicationId: string;
  customerId: string;
  filename: string;
}

export interface UploadInput {
  customerId: string;
  filename: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
