import { ApolloError } from "@apollo/client";

export const graphQLError = (error: ApolloError) => {
  if (isGraphQLError(error))
    return error.graphQLErrors.map((error) => error.message);

  return [];
};

const isGraphQLError = (error: any): error is ApolloError =>
  error !== null &&
  typeof error === "object" &&
  "graphQLErrors" in error &&
  Array.isArray(error.graphQLErrors);
