import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { posthog } from "posthog-js";

import { FeatureFlag } from "@type/posthog";

interface FeatureFlagsContextType {
  isGroundApplicationEnabled: boolean;
  isCustomerPilotCreationEnabled: boolean;
  isTrackInputEnabled: boolean;
}

interface FeatureFlagsProviderProps {
  children: ReactNode;
}

export const FeatureFlagsContext = createContext<FeatureFlagsContextType>(
  {} as FeatureFlagsContextType
);

export const FeatureFlagsProvider = ({
  children,
}: FeatureFlagsProviderProps) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>([]);

  useEffect(() => {
    posthog.onFeatureFlags((flags) => {
      setFeatureFlags(flags as FeatureFlag[]);
    });
  }, []);

  const isGroundApplicationEnabled = useMemo(
    () => featureFlags.includes("enable-ground-applications"),
    [featureFlags]
  );

  const isCustomerPilotCreationEnabled = useMemo(
    () => featureFlags.includes("enable-customer-pilot-creation"),
    [featureFlags]
  );

  const isTrackInputEnabled = useMemo(
    () => featureFlags.includes("enable-track-input"),
    [featureFlags]
  );

  return (
    <FeatureFlagsContext.Provider
      value={{
        isGroundApplicationEnabled,
        isCustomerPilotCreationEnabled,
        isTrackInputEnabled,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
