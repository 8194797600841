import { createContext, ReactNode, useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { useLazyQuery } from "@apollo/client";

import { GET_CUSTOMER } from "@graphql/queries/CustomerQuery";
import {
  GetCustomer as GetCustomerInput,
  GetCustomerVariables,
} from "@graphql/types/GetCustomer";

import { graphQLError } from "@utils/errorMessages";

import { Error403Page, Error404Page, Error500Page } from "@pages/ErrorPage";

import { GetCustomer } from "@type/customers";

interface CustomerContextType {
  customer: GetCustomer | null;
}

interface CustomerProviderProps {
  children: ReactNode;
}

const CustomerContext = createContext<CustomerContextType>(
  {} as CustomerContextType
);

function CustomerProvider({ children }: CustomerProviderProps) {
  const navigate = useNavigate();

  const match = useMatch("/customers/:customerId/*");
  const [customer, setCustomer] = useState<GetCustomer | null>(null);

  const [getCustomer] = useLazyQuery<GetCustomerInput, GetCustomerVariables>(
    GET_CUSTOMER,
    {
      onCompleted: (customerItem) => {
        setCustomer(customerItem.customer);
      },
      onError: (error) => {
        const messages = graphQLError(error);
        const statusCode =
          error.graphQLErrors.length > 0
            ? error.graphQLErrors[0].extensions.response.statusCode
            : "error";
        console.error(
          "[error]: ",
          error.graphQLErrors[0].extensions.response.statusCode
        );

        switch (statusCode) {
          case 403:
            navigate(Error403Page.createPath());
            console.error("[forbidden]: ", messages);
            break;
          case 404:
            navigate(Error404Page.createPath());
            console.error("[not found]: ", messages);
            break;
          case 500:
            navigate(Error500Page.createPath());
            console.error("[500]: ", messages);
            break;
          default:
            navigate(Error500Page.createPath());
            console.error("[other error]: ", messages);
            break;
        }
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    const customerId = match?.params.customerId;
    if (customerId && !customer) {
      getCustomer({
        variables: {
          customerId: customerId,
        },
      });
    }
    if (customer && !customerId) {
      setCustomer(null);
    }
  }, [match]);

  return (
    <CustomerContext.Provider value={{ customer }}>
      {children}
    </CustomerContext.Provider>
  );
}

export { CustomerContext, CustomerProvider };
